/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 4, 2024 */



@font-face {
    font-family: 'montserrat_alternateslight';
    src: url('montserratalternates-light-webfont.woff2') format('woff2'),
         url('montserratalternates-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat_alternatesmedium';
    src: url('montserratalternates-medium-webfont.woff2') format('woff2'),
         url('montserratalternates-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat_alternatesthin';
    src: url('montserratalternates-thin-webfont.woff2') format('woff2'),
         url('montserratalternates-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}