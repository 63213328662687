:root {
  --var-principal-color:#650F2A;
  --var-secondary-color:#fff;
  --var-terciary-color:#ccc;
  --var-disabled-color:#340011;
  --var-font-color:#340011;
}
#root.loading::before {
  content: " ";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(../img/loading.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  background-color: rgba(101, 15, 42 , 0.4);
  z-index: 1031;
}
.bg-color-principal {
  background-color: var(--var-principal-color);
}
.bg-color-secondary {
  background-color: var(--var-secondary-color);
}
.bg-color-terciary {
  background-color: var(--var-terciary-color);
}
.bg-color-disabled {
  background-color: var(--var-disabled-color);
}
.font-color-principal {
  color: var(--var-secondary-color);
}
.btn.btn-gts {
  --bs-btn-color: var(--var-secondary-color);
  --bs-btn-bg: var(--var-principal-color);
  --bs-btn-border-color: var(--var-principal-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--var-disabled-color);
  --bs-btn-disabled-border-color: var(--var-disabled-color);
  border-radius: 0;
}
.btn-check:checked + .btn.btn-gts, 
.btn.btn-gts.active, 
.btn.btn-gts.show, 
.btn.btn-gts:first-child:active, 
:not(.btn-check) + .btn.btn-gts:active,
.btn.btn-gts:hover,.btn.btn-gts:active
{
  color: var(--var-principal-color);
  background-color: var(--var-secondary-color);
  border-color: var(--var-principal-color);
}
.page-container {
  overflow: auto;
  margin: 15px;
  min-height: calc(100vh - 139px);
}

.page-container .title {
  text-align: center;
}
.page-container .sub-title {
  text-align: center;
  padding: 20px 0;
}
.page-container .panel-actions {
  position: relative;
  padding: 10px 0;
  text-align: right;
  min-height: 50px;
}
.page-container .panel-actions.floating {
  text-align: left;
}
.page-container .panel-actions.floating .btn-gts {
  position: absolute;
  right: 0;
}
.page-container .panel-actions.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.page-container .panel-actions .select-control {
  min-width: 200px;
  margin-right: 10px;
}
.page-container .panel-search {
  padding: 20px 0;
}
.page-container .reduce-font-mobile .btn,
.page-container .reduce-font-mobile .MuiFormControlLabel-label{
  font-size: 14px;
}
.MuiTableContainer-root {
  width: auto;
}
.MuiTable-root.table-gts .MuiTableCell-root {
  padding: 14px 5px;
  line-height: 1;
}
.MuiTable-root.table-gts .MuiTableCell-root.icons-actions {
  padding: 0 5px;
}
.MuiTable-root.table-gts .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head {
  background-color: var(--var-principal-color);  
}
.MuiTable-root.table-gts .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  color: var(--var-font-color);  
}
.MuiTable-root.table-gts .MuiButtonBase-root {
  padding: 0;
}
.MuiTable-root.table-gts .MuiButtonBase-root.MuiSwitch-switchBase {
  padding: 9px;
}
.MuiTable-root.table-gts .MuiSvgIcon-root {
  color: var(--var-principal-color);
}
.MuiTable-root.table-gts .btn-gts {
  padding: 0;
  border-radius: 5px;
  margin-right: 5px;
}
.MuiTable-root.table-gts .btn-gts .MuiSvgIcon-root {
  color: var(--var-secondary-color);
  width: 25px;
}
.MuiTable-root.table-gts .btn-gts .MuiSvgIcon-root:hover {
  color: var(--var-principal-color);
}
.MuiTable-root.table-gts .MuiInputBase-input {
  padding: 5px;
  height: auto;
  text-align: right;
}
.MuiField-root-switch-container {
  height: 38px;
}
.form-gst {
  max-width: 1024px;
  margin: 20px auto;
}
.panel-search .MuiFormControl-root,
.form-gst .MuiFormControl-root {
  min-width: 240px;
}
.panel-search .MuiFormLabel-root,
.form-gst .MuiFormLabel-root {
  line-height: 1;
  transform: translate(10px, 12px) scale(1);
}
.panel-search .MuiFormLabel-root.Mui-focused,
.form-gst .MuiFormLabel-root.Mui-focused {
  color: var(--var-principal-color);
}
.panel-search .MuiFormLabel-root.MuiInputLabel-shrink,
.form-gst .MuiFormLabel-root.MuiInputLabel-shrink {
  transform: translate(14px, -9px) scale(0.75);
}
.form-gst .list-informacion-proporcionada .list-check-control {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.form-gst .list-informacion-proporcionada .list-check-control .MuiFormGroup-root.MuiField-root-switch {
  width: 25%;
  margin: 0 0 10px 0;
}
.form-gst .list-informacion-proporcionada .list-check-control .MuiFormGroup-root.MuiField-root-switch .MuiFormControlLabel-label {
  font-size: 14px;
}
.form-gst .list-informacion-proporcionada .list-check-control .MuiFormGroup-root.MuiField-root-switch .MuiFormControlLabel-label .sub-label {
  font-size: 12px;
}
.form-gst .list-seguimiento-cliente .MuiFormLabel-root,
.form-gst .list-informacion-proporcionada .MuiFormLabel-root {
  display: block;
  text-align: center;
  line-height: 2;
  margin-bottom: 20px;
}
.form-gst .list-seguimiento-cliente .MuiFormGroup-root.MuiField-root-switch {
  width: 120px;
}
.form-gst .list-seguimiento-cliente .MuiFormGroup-root.MuiField-root-switch .MuiFormControlLabel-root {
  margin-left: 0;
}
.panel-search .MuiInputBase-root,
.form-gst .MuiInputBase-root {
  border-radius: 0;
}
.panel-search .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.form-gst .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--var-principal-color);
}
.panel-search .MuiInputBase-input,
.form-gst .MuiInputBase-input {
  padding: 10px;
  height: auto; 
  color: var(--var-principal-color);
}
.form-gst .MuiTextField-root {
  margin: 8px;
  width: calc(25% - 16px);
}
.form-gst .MuiTextField-root.long-textarea {
  width: calc(100% - 16px);
}
.form-gst .panel-inputs.inactive {
  display: none;
}
.form-gst .panel-inputs.active {
  display: block;
}
.form-gst .panel-inputs.estatus {
  margin-top: 50px;
}
.MuiTextField-root .MuiSvgIcon-root {
  fill: var(--var-principal-color);
}
.form-gst .MuiFormControl-root.select-control {
  margin: 8px;
  width: calc(25% - 16px);
}
.form-gst .MuiFormGroup-root.MuiField-root-switch {
  display: inline-flex;
  margin: 8px;
  width: 100px;
}
.form-gst .MuiFormGroup-root.MuiField-root-switch.double {
  width: 240px;
}
.TabsGTS .panel-actions.center {
  text-align: center;
}
.TabsGTS .panel-actions .btn.btn-gts {
  margin-left: 5px;
}
.form-gst .panel-actions .btn.btn-gts{
  margin-left: 5px;
}
.dialog-seguimiento-cliente-prospecto .form-gst {
  margin: 0;
}
.dialog-seguimiento-cliente-prospecto .form-gst .select-control,
.dialog-seguimiento-cliente-prospecto .form-gst .MuiFormControl-root {
  width: 100%;
}
.MuiDialog-root .MuiDialogTitle-root {
  background-color: var(--var-principal-color);
  color: var(--var-secondary-color);
}
.MuiDialog-root .close-modal {
  position: absolute; 
  right: 8px;
  top: 8px; 
  color: var(--var-secondary-color);
}
input.form-control {
  border-radius: 0;
}
input.form-control:focus {
  border-color: var(--var-principal-color);
  box-shadow: none;
}
#root > header {
  background-color: var(--var-principal-color);
  position: relative;
  height: 41px;
}
#root > header .navbar {
  background-color: var(--var-principal-color);
  padding: 0;
}
#root > header .navbar .navbar-toggler {
  border: 0;
  border-radius: 0;

}
#root > header .navbar .navbar-toggler.collapsed {
  background-color: var(--var-principal-color);
}
#root > header .navbar .navbar-toggler:focus {
  box-shadow: none;
}
#root > header .navbar .navbar-toggler .navbar-toggler-icon {
  color: var(--var-secondary-color);
}
#root > header .navbar .card-user {
  color: #fff;
  height: 40px;
  font-size: 14px;
  position: absolute;
  right: 80px;
  max-width: 220px;
  overflow: hidden;
  top:0;
}
#root > header .navbar .card-user .name {
  display: inline-block;
  padding-top: 10px;
}
body {
  color: var(--var-font-color) !important;
}
#root > footer {
  background-color: var(--var-principal-color);
  color: var(--var-secondary-color);
  text-align: center;
  padding: 10px;
}
#menuLinkHome {
  padding-left: 50px;
  background-image: url(../img/logo.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}
.dropdown-menu {
  border-radius: 0 !important;
  border-color: var(--var-principal-color) !important;
}
.dropdown-menu .dropdown-item {
  color: var(--var-principal-color);
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: var(--var-secondary-color);
  background-color: var(--var-principal-color);
}
.nav-item .dropdown-toggle:after {
  display: inline-block;
  margin-left: 1em;
  vertical-align: .255em;
  content: "";
  border-top: .2em solid;
  border-right: .2em solid transparent;
  border-bottom: 0;
  border-left: .2em solid transparent;
}
.nav-item.dropdown.active .nav-link {
  background-color: var(--var-secondary-color);
  color: var(--var-principal-color);
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link {
  color: var(--var-secondary-color) ;
  border-radius: 0;
}
.nav-tabs .nav-link.show,
.nav-tabs .nav-link.active {
  color: var(--var-principal-color) !important;
  border-radius: 0;
}
.nav-user-dropdown {
  color: var(--var-secondary-color);
  margin-right: 10px;
  position: absolute !important;
  top: 0;
  right: 0;
}
.nav-user-dropdown .MuiSvgIcon-root {
  vertical-align: top;
}
.nav-user-dropdown .dropdown-toggle.nav-link {
  display: inline-block;
  height: 39px;
  line-height: 36px;
}
.nav-user-dropdown .dropdown-toggle.nav-link .name-dealer {
  display: inline-block;
  padding-left: 10px;
}
.nav-user-dropdown .dropdown-toggle.nav-link .name {
  display: block;
  line-height: 20px;
}
.nav-user-dropdown .dropdown-toggle::after {
  vertical-align: middle;
  margin-top: -15px;
}
.nav-user-dropdown .dropdown-toggle.nav-link .dealer {
  display: block;
  line-height: 15px;
  font-size: 12px;
}
.nav-user-dropdown .dropdown-menu {
  right: -10px;
  text-align: center;
  color: var(--var-principal-color);
}
.nav-user-dropdown .MuiSvgIcon-root {
  width: 36px;
  height: 36px;
}
.nav-user-dropdown .name-movile {
  display: none;
}
.MuiPaper-root.MuiAppBar-colorPrimary {
  background-color: var(--var-principal-color) !important;
}
.panel-totals .total {
  text-align: right;
  border-top: 1px solid var(--var-principal-color);
}
.panel-totals .total .value {
  display:inline-block;
  padding: 10px;
  background-color: var(--var-principal-color);
  color: var(--var-secondary-color);
  min-width: 150px;
  font-weight: bold;
}
.panel-totals .total .label {
  display:inline-block;
  padding: 9px;
  color: var(--var-principal-color);
  font-weight: bold;
  border-left: var(--var-principal-color) 1px solid;
  border-bottom: var(--var-principal-color) 1px solid;
}
.TabsGTS .TabsGTS-Label .MuiButtonBase-root {
  font-size: 16px;
  font-weight: bold;
  color: var(--var-principal-color);
  text-transform: none;
}
.TabsGTS .TabsGTS-Label .MuiTabs-indicator {
  background-color: var(--var-principal-color);
}
#products-container .list-products {
  text-align: center;
  padding: 0;
}
#products-container .list-products .item-product {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 125px;
  padding: 10px;
  border: 2px solid var(--var-principal-color);
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
#products-container .list-products .item-product .image {
  width: 100%;
  height: 100px;
  overflow: hidden;
  text-align: center;
}
#products-container .list-products .item-product .image img {
  width: 100px;
}
#products-container .list-products .item-product .text-product{
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  height: 56px;
  overflow: hidden;
}
#products-container .list-products .item-product .presentation {
  font-size: 10px;
}
#products-container .list-products .item-product .price {
  font-size: 12px;
}
#products-container .list-products .item-product .controls {
  
  padding-top: 10px;
}
#products-container .list-products .item-product .add-product {
  position: absolute;
  bottom: 2px;
  left: 2px;
  font-size: 38px;
  background-color: var(--var-principal-color);
  color: var(--var-secondary-color);
  border: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
}
#products-container .list-products .item-product .minus-product {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 35px;
  background-color: var(--var-secondary-color);
  color: var(--var-principal-color);
  border: 2px solid var(--var-principal-color);
  width: 35px;
  height: 35px;
  line-height: 32px;
  border-radius: 50%;
}
#products-container .list-products .item-product .cantity {
  font-size: 20px;
  color: var(--var-principal-color);
  font-weight: bold;
}
.product-table .controls {
  text-align: center;
}
.product-table .controls .add-product,
.product-table .controls .minus-product {
  background-color: var(--var-principal-color);
  color: var(--var-secondary-color);
  border: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 38px;
}
.product-table .controls .input-cantidad {
  width: 100px;
}
@media screen and (max-width: 580px) {
  #root > header:before {
    content: " ";
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 41px;
    height: 41px;
    position: absolute;
    z-index: 1031;
    right: 0;
  }
  #root > header .navbar .card-user {
    right: 50px;
  }
  .nav-user-dropdown .dropdown-toggle.nav-link {
    margin-right: 40px;
  }
  .nav-user-dropdown .dropdown-toggle.nav-link .name {
    display: none;
  }
  .nav-user-dropdown .dropdown-menu {
    left: auto !important;
    width: 70vw;
    min-width: 150px;
  }
  .nav-user-dropdown .name-movile {
    display: block;
  }
  #menuLinkHome {
    padding-left: 0;
    background-image: none;
  }
  .movile-hidden {
    display: none !important;
  }
  .TabsGTS .TabsGTS-Label .MuiButtonBase-root {
    padding: 5px;
  }
  .MuiTable-root.table-gts .MuiTableCell-root {
    padding: 10px 5px;
    font-size: 12px;
  }
}
@media screen and (max-width: 450px) {
  .page-container.without-margin {
    margin: 0;
  }
  .panel-search .MuiFormControl-root {
    width: 100%;
  }
  .form-gst .MuiFormControl-root {
    width: calc(100% - 16px);
  }
  .form-gst .MuiFormControl-root.select-control{
    width: calc(100% - 16px);
  }
}